<template>
  <v-app-bar flat dense v-c:K :color="cor.active.B" :dark="isDark">

    <v-app-bar-nav-icon @click="HambClick"></v-app-bar-nav-icon>

    <div v-c:K class="d-none d-md-block f-raleway fw-500 fs-13pt mt-0 ms-2 ps-0">
      <span v-if="areaSel == 'todas'">
        <span v-i:duo#star#24 :style="'color: '+areaSel.cor+'; fill: '+areaSel.cor" class="btn-icon-left"></span>
        <span>Todas áreas</span>
      </span>
      <span v-if="areaSel.id!= undefined && areaSel.id == 'todos'" v-i:duo#star#24 :style="'color: '+areaSel.cor+'; fill: '+areaSel.cor" class="btn-icon-left"></span>
      <span v-else v-i:duo#calendar#24 :style="'color: '+areaSel.cor+'; fill: '+areaSel.cor" class="btn-icon-left"></span>
      <span class="f-yanone fw-500 align-middle fs-14pt" :style="'color: '+areaSel.cor+'; fill: '+areaSel.cor">{{areaSel.title}}</span>
      <span class="ms-4 fs-10pt fw-500">Visão:</span>
      <span class="ms-2 fs-11pt fw-700">{{route}}</span>
    </div>

  </v-app-bar>
</template>

<script>
import { eventBus } from "@/main.js";
import moment from 'moment';

export default {
  name: 'topmenu',
  components: { },
  props: [ "route" ],
  data(){
    return {
      barHeight: 0,
      navBelowFold: true,
      drawer: null,
      user: {},
      rating: 4,
      barHidden: false,
      areaSel: "Todas áreas",
    }
  },
  computed: {
    pageYOffset: function() {
      return window.pageYOffset;
    },
    fundo: function() {
      return {
        '--colorbg1': this.palette.B.dd,
        '--colorbg2': this.palette.B.l,
      }
    },
    moment() {
      return moment;
    }
  },
  directives: {
  },
  created() {
    console.log("Top menu Created");
  },
  mounted() {
    var self = this;
    //console.log("Top menu Mounted")
    //self.user = self.$session.get('user');
    eventBus.$on("menuClick", function(item) {
      self.areaSel = item;
    });

  },
  methods: {

    HambClick() {
      eventBus.$emit('hamburger-click2');
    },

  }
}

</script>

<style scoped>

</style>
