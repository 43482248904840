<template>
  <div class="row px-1 pt-1">
    <div class="col-12">
      <v-card outlined class="mt-1" style="min-height: 200px;">
        <v-card-text class="">
          <v-row>
            <v-col cols="12" lg="12" class="my-0 py-0">
              <v-btn @click="$refs.formeventoref.show()" class="text-nonecase m-0 mt-1 py-0 px-1" outlined small>
                <i v-i:duo#plus-circle#16 class="btn-icon-left"></i>
                <span>adicionar evento</span>
              </v-btn>
            </v-col>
            <v-col cols="12" lg="6">
              <treeselect v-model="setorSel" multiple :normalizer="normalizer"
              :options="vetSetores" placeholder="Filtrar por áreas">
              <div slot="option-label" slot-scope="{ node }">
                <span class="fs-10pt fw-300 truncate">{{node.raw.nome}}</span>
              </div>
              <div slot="value-label" slot-scope="{ node }">
                <span class="fs-7pt fw-300 truncate">{{node.raw.nome.substr(0,10)}}</span>
              </div>
            </treeselect>
          </v-col>
          <v-col cols="12" lg="6" class="my-0 py-0">
            <v-autocomplete class="my-0 pt-2 ms-4" dense solo v-model="selecionados" :items="itensBusca" chips color="blue-grey lighten-2" label="Buscar">
              <template v-slot:item="data" class="mt-0 py-0" style="">
                <div class="my-0 py-0" style="max-width: 400px;">
                  <i class="left" v-i:duo#calendar#16></i>
                  <p class="left fw-300 f-lato fs-8pt ms-2 mt-1 py-0">
                    <span class="">{{data.item.substr(0,75)}}</span>
                  </p>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <hr class="mt-0">
        <v-list two-line class="mt-3">
          <div class="" v-for="(evento,key,index) in eventosList" :key="index" v-if="index < 20">
            <v-list-item class="mb-0 pb-0 mt-n2">
              <v-list-item-avatar size="52" class="mt-0">
                <v-avatar size="52">
                  <i v-i:duo#calendar#30></i>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content class="text-left my-0 pt-2">
                <v-list-item-title class="my-0 py-0">
                  <span :style="'color: '+evento.cor+'; fill: '+evento.cor">
                    <i class="btn-icon-left" v-i:duo#record#20></i>
                    <span class="me-3 fs-11pt f-lato fw-700 f-lato">{{evento.setor}}</span>
                  </span>
                  <span v-for="equipe in evento.equipes">
                    <span class="fw-700 fs-9pt me-1 f-roboto">Equipe: </span>
                    <span class="fw-500 fs-10pt me-1 f-lato">{{equipe.nome}}</span>
                    <v-tooltip bottom v-for="(user,index) in equipe.pessoas" :key="index">
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="28" v-bind="attrs" v-on="on" class="" style="margin-right: -4px;">
                          <img class="sz28" :src="user.urlFoto" alt="foto">
                        </v-avatar>
                      </template>
                      <span>{{user.nome}}</span>
                    </v-tooltip>
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="ms-0 ps-0 my-0 py-0">
                  <p class="ms-0 my-0 py-0 fs-10pt f-raleway">
                    <span class="fw-300 fs-10pt f-lato">Início: </span>
                    <span class="me-3 fw-800 fs-10pt f-roboto">{{moment(evento.dataInicio).format("DD/MM/YYYY")}}</span>
                    <span class="fw-300 fs-10pt f-lato">Término: </span>
                    <span class="me-3 fw-800 fs-10pt f-roboto">{{moment(evento.dataFinal).format("DD/MM/YYYY")}}</span>
                    <span v-if="evento.alunos != undefined" class="right me-3 px-1 fw-400 fs-10pt f-roboto rounded" v-bg:b>alunos</span>
                    <span v-if="evento.professores != undefined" class="right me-3 px-1 fw-400 fs-10pt f-roboto rounded" v-bg:b>professores</span>
                    <span v-if="evento.diretores != undefined" class="right me-3 px-1 fw-400 fs-10pt f-roboto rounded" v-bg:b>diretores</span>
                    <span v-if="evento.coordenador != undefined" class="right me-3 px-1 fw-400 fs-10pt f-roboto rounded" v-bg:b>coordenador</span>
                    <span v-if="evento.VPA != undefined" class="right me-3 px-1 fw-400 fs-10pt f-roboto rounded" v-bg:b>VPA</span>
                    <span class="fw-500 fs-10pt f-lato right me-2">Público: </span>
                  </p>
                  <p class="ms-0 mt-1 mb-3 py-0 fs-11pt f-raleway">
                    <span class="fw-700">{{evento.descricao}}</span>
                  </p>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon  class="px-0 mx-0">
                <v-btn class="px-0 mx-0" :color="cor.active.D" small icon @click="$refs.promptdialogref.show(evento)">
                  <i class="btn-icon-left ps-1" v-i:duo#trash#20></i>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-icon  class="px-0 mx-0 ms-1">
                <v-btn class="px-0 mx-0" :color="cor.active.I" small icon @click="formShow(evento)">
                  <i class="btn-icon-left ps-1" v-i:duo#pencil#20></i>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
            <v-divider inset class="mt-n4"></v-divider>
          </div>
        </v-list>

      </v-card-text>
      <v-overlay class="m-1 p-1" :absolute="true" :opacity="1" :value="loading" :color="cor.active.b" :key="refresh">
        <div class="my-1 py-1">
          <p class="text-center mt-0">
            <img width="180px" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
          </p>
          <p class="text-center">
            <v-progress-circular indeterminate size="64" v-c="cor.P._"></v-progress-circular>
          </p>
          <p class="text-center" v-c="cor.P._">
            carregando...
          </p>
        </div>
      </v-overlay>

    </v-card>
  </div>
  <formevento ref="formeventoref" @update="formUpdate"/>
  <promptdialog ref="promptdialogref" @confirm="confirmaRemover" />

</div>
</template>

<script>
// @ is an alias to /src
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'
import appoverlay from "@/components/appoverlay.vue"
import formevento from "./formEvento.vue";
import promptdialog from "./promptdialog.vue";
import moment from 'moment';

export default {
  name: 'listeventos',
  components: { dummy, appoverlay, formevento, promptdialog },
  computed: {
  },
  data () {
    return {
      selecionados: null,
      itensBusca: [],
      refresh: 0,
      loading: false,
      setores: {},
      vetSetores: [],
      eventos: {},
      eventosList: {},
      eventoParaAtualizar: {},
      setorSel: [],
      moment: moment,
    }
  },
  watch: {
    selecionados() {
      console.log(this.selecionados);
      this.showBusca();
    },
    setorSel() {
      console.log(this.setorSel);
      this.showBusca();
    }
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    this.refresh++;
    this.$forceUpdate();
    self.itensBusca = [];

    setTimeout(function() {
      rdb.ref('/usuarios/setores').on('value', function(snapshot) {
        self.setores = snapshot.val();
        self.vetSetores = snapshotValToArray(snapshot.val());
        console.log("self.setores",self.setores);
        rdb.ref('/calendarios/2021S2/eventos').on('value', function(snapshot) {
          self.eventos = snapshot.val();
          for(var key in self.eventos) {
            for(var keySetor in self.eventos[key].setores) {
              self.eventos[key].setores[keySetor] = self.setores[keySetor];
              self.eventos[key].cor = self.setores[keySetor].cor.hexa;
              //console.log(self.eventos[key].cor);
            }
            self.itensBusca.push(self.eventos[key].descricao);
          }
          console.log(self.eventos);
          self.build();
          self.loading = false;
        });
      });
    },200);
  },

  methods: {

    build() {
      var self = this;
      console.log("build");
      self.eventosList = {};
      self.itensBusca = [];
      for(var key in self.eventos) {
        if(self.setorSel.length == 0) {
          if(this.selecionados == null) {
            self.eventosList[key] = self.eventos[key];
            self.itensBusca.push(self.eventos[key].descricao);
          } else {
            if(self.eventos[key].descricao == this.selecionados) {
              self.eventosList[key] = self.eventos[key];
              self.itensBusca.push(self.eventos[key].descricao);
            }
          }
        } else {
          for(var keyArea in self.setorSel) {
            if(self.eventos[key].setores[self.setorSel[keyArea]] != undefined) {
              if(this.selecionados == null) {
                self.eventosList[key] = self.eventos[key];
                self.itensBusca.push(self.eventos[key].descricao);
              } else {
                if(self.eventos[key].descricao == this.selecionados) {
                  self.eventosList[key] = self.eventos[key];
                  self.itensBusca.push(self.eventos[key].descricao);
                }
              }
              break;
            }
          }
        }
      }
      console.log("self.eventosList",self.eventosList);
    },

    normalizer(node) {
      return {
        id: node.id,
        label: node.nome,
      }
    },

    formShow(item) {
      const evento = clone2(item);
      this.eventoParaAtualizar = item;
      this.$refs.formeventoref.show(evento);
    },

    formUpdate(dados) {
      console.log("dados",dados);
      var updates = {};
      var elem = clone2(dados.formData);
      if(dados.tipo == "Atualizar") {
        console.log("Atualizar");
        console.log("elem",elem);
        updates["/calendarios/2021S2/eventos/"+elem.id] = elem;
        console.log(updates);
        rdb.ref().update(updates);
        this.toastS("Dados alterados com sucesso!");
        console.log("agora já elvis!");
      } else {
        var newKey = rdb.ref('/calendarios/2021S2/eventos').push().key;
        elem.id = newKey;
        updates["/calendarios/2021S2/eventos/"+newKey] = elem;
        console.log(updates);
        rdb.ref().update(updates);
        this.toastS("Dados cadastrados com sucesso!");
        console.log("agora já elvis!");
      }
    },

    confirmaRemover(item) {
      console.log("confirmaRemover",item);
      var elem = clone2(item);
      var updates = {};
      updates["/calendarios/2021S2/eventos/"+elem.id] = null;
      console.log(updates);
      rdb.ref().update(updates);
      this.toastS("Item removido com sucesso!");
      console.log("agora já elvis!");
    },

    showBusca() {
      var self = this;
      this.build();
    },

  }
}
</script>
