<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card class="pb-2">
      <v-card-title class="fs-13pt my-0 pb-1" v-c:I>
        <span class="fw-700 f-raleway">{{btnName}}</span>
        <span class="ps-1 fw-300 f-raleway">nova equipe</span>
      </v-card-title>
      <v-divider class="mt-0 mb-1 py-0"></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12 my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Nome da equipe</p>
              <v-text-field v-model="formData.nome" :rules="[rules.required]" solo dense label="Nome da equipe" required placeholder="Digite o nome da equipe"></v-text-field>
            </v-col>
            <v-col cols="12 my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Membros da equipe</p>
              <selectusers ref="selectusersref" :model="formData.pessoas" @changeValue="changeSelValue"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn :color="cor.active.S" small @click="dialog = false">
          cancelar
        </v-btn>
        <v-btn :color="cor.active.V" v-c:B small @click="update">
          <i class="btn-icon-left" v-i:duo#check-circle#18></i>
          {{btnName}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import selectusers from "./selectusers.vue";

export default {
  name: "formequipe",
  components: { selectusers },
  data() {
    return {
      dialog: false,
      loading: false,
      btnName: "Adicionar",
      formData: {
        nome: "",
        pessoas: {},
      },
      rules: {
        required: value => !!value || 'Obrigatório',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    self.loading = false;
  },

  methods:{

    show(dados) {
      console.log("dados",dados);
      this.dialog = true;
      if(dados == null) {
        this.formData = { nome: "", pessoas: {} };
        this.btnName = "Adicionar";
      } else {
        this.formData = clone2(dados);
        this.btnName = "Atualizar";
      }
      console.log("this.formData",this.formData);
    },

    changeSelValue(value) {
      console.log("changeSelValue",value);
      this.formData.pessoas = {};
      for(var key in value) {
        this.formData.pessoas[value[key].id] = value[key];
      }
    },

    update() {
      if(this.formData.nome == "") {
        this.toastD("Nome da equipe obrigatória!");
        return;
      }
      var selecionados = this.$refs.selectusersref.getSelecionados();
      if(Object.keys(selecionados).length == 0) {
        this.toastD("Nenhuma pessoa incluida!");
        return;
      }
      this.dialog = false;
      console.log("selecionados",selecionados);
      this.formData.pessoas = selecionados;
      this.$emit("update",{ formData: this.formData, tipo: this.btnName } );
    }

  }
}
</script>

<style scoped>

</style>
