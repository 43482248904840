<template>
  <v-row class="mt-0 p-0" v-bg:b>
    <v-col cols="12" sm="3" md="3" class="p-0 m-0">
      <svgcalendar class="p-0 m-0" width="100%" height="120" />
    </v-col>
    <v-col cols="12" sm="9" md="4">
      <p class="ms-0 mt-2 mb-2 f-raleway fw-800 fs-14pt" v-cHex="hexShades(cor.active.B,0.5)">
        <img width="26px" class="mt-n1 me-1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-icone.png?alt=media&token=e79300a1-6691-4a84-b4c6-6f6086ba3c92" alt="">
        Calendários Digitais
      </p>
      <v-card class="my-0 me-4 p-2" v-bg:b>
        <p class="m-0 p-0 f-raleway fs-9pt">
          Calendários Acadêmicos Digitais
        </p>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12" md="5">
      <p class="ms-1 my-0 f-sanspro fw-400 fs-11pt" v-cHex="hexShades(cor.active.B,0.5)">
        <i class="btn-icon-left" v-i:duo#users#18 v-cHex="hexShades(cor.active.B,0.5)"></i>
        Equipe
      </p>
      <div class="left mt-0 mb-0 pt-0 ms-0" v-if="equipe != null">
        <avataronline v-for="(user,key,index) in equipe.pessoas" :key="index" :userID="user.id" :size="34"/>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import svgcalendar from "@/components/svgs/svg-calendar.vue"
import avataronline from "@/components/avatar-online.vue";

export default {
  name: "admintopo",
  components: { svgcalendar, avataronline },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      equipe: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    rdb.ref('/usuarios/equipes/-MdK4fS4aV2BIyM728_E').on('value', function(snapshot) {
      self.equipe = snapshot.val();
      //console.log("self.equipe",self.equipe);
    });
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
