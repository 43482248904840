var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pb-2"},[_c('v-card-title',{directives:[{name:"c",rawName:"v-c:I",arg:"I"}],staticClass:"fs-13pt my-0 pb-1"},[_c('span',{staticClass:"fw-700 f-raleway"},[_vm._v(_vm._s(_vm.btnName))]),_c('span',{staticClass:"ps-1 fw-300 f-raleway"},[_vm._v(" evento")])]),_c('v-divider',{staticClass:"mt-0 mb-1 py-0"}),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"fw-300 fs-10pt f-roboto my-0 pb-1 ps-1"},[_vm._v("Tema do evento")]),_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"solo":"","dense":"","label":"Tema do evento","required":"","placeholder":"Digite o tema do evento"},model:{value:(_vm.formData.tema),callback:function ($$v) {_vm.$set(_vm.formData, "tema", $$v)},expression:"formData.tema"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"fw-300 fs-10pt f-roboto my-0 pb-1 ps-1"},[_vm._v("Descrição do evento")]),_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"solo":"","dense":"","label":"Descrição do evento","required":"","placeholder":"Digite a descrição do evento"},model:{value:(_vm.formData.descricao),callback:function ($$v) {_vm.$set(_vm.formData, "descricao", $$v)},expression:"formData.descricao"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","lg":"6"}},[_c('p',{staticClass:"fw-300 fs-10pt f-roboto my-0 pb-1 ps-1"},[_vm._v("Data de Início")]),_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.dataInicioFormated,"prepend-icon":"mdi-calendar"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDataInicio),callback:function ($$v) {_vm.menuDataInicio=$$v},expression:"menuDataInicio"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.menuDataInicio = false}},model:{value:(_vm.formData.dataInicio),callback:function ($$v) {_vm.$set(_vm.formData, "dataInicio", $$v)},expression:"formData.dataInicio"}})],1)],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","lg":"6"}},[_c('p',{staticClass:"fw-300 fs-10pt f-roboto my-0 pb-1 ps-1"},[_vm._v("Data de Término")]),_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.dataFinalFormated,"prepend-icon":"mdi-calendar"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDataFinal),callback:function ($$v) {_vm.menuDataFinal=$$v},expression:"menuDataFinal"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.menuDataFinal = false}},model:{value:(_vm.formData.dataFinal),callback:function ($$v) {_vm.$set(_vm.formData, "dataFinal", $$v)},expression:"formData.dataFinal"}})],1)],1),_c('v-col',{staticClass:"mt-0 mb-1 py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"fw-300 fs-10pt f-roboto my-0 pb-1 ps-1"},[_vm._v("Áreas")]),_c('treeselect',{attrs:{"multiple":"","normalizer":_vm.normalizer,"options":_vm.vetSetores,"placeholder":"Selecionar áreas"},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_c('span',{staticClass:"fs-10pt fw-300 truncate"},[_vm._v(_vm._s(node.raw.nome))])])}},{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_c('span',{staticClass:"fs-7pt fw-300 truncate"},[_vm._v(_vm._s(node.raw.nome.substr(0,30)))])])}}]),model:{value:(_vm.setorSel),callback:function ($$v) {_vm.setorSel=$$v},expression:"setorSel"}})],1),(false)?_c('v-col',{staticClass:"my-1 py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"fw-300 fs-10pt f-roboto my-0 pb-1 ps-1"},[_vm._v("Equipe")]),_c('v-autocomplete',{key:_vm.refresh,staticClass:"my-0 pt-2 ms-0",attrs:{"dense":"","solo":"","items":_vm.vetEquipes,"chips":"","color":"blue-grey lighten-2","label":"Selecione equipe"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"my-0 py-0",staticStyle:{"max-width":"400px"}},[_c('i',{directives:[{name:"i",rawName:"v-i:duo#users#16",arg:"duo#users#16"}],staticClass:"left"}),_c('p',{staticClass:"left fw-300 f-lato fs-8pt ms-2 mt-1 py-0"},[_c('span',{},[_vm._v(_vm._s(data.item.nome.substr(0,75)))])])])]}},{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){_vm.equipeSel = []}}},'v-chip',data.attrs,false),[_vm._l((data.item.pessoas),function(user,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticStyle:{"margin-right":"-4px"}},'v-avatar',attrs,false),on),[_c('img',{staticClass:"sz30",attrs:{"src":user.urlFoto,"alt":"foto"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(user.nome))])])}),_c('span',{staticClass:"ms-3 fs-8pt"},[_vm._v(_vm._s(data.item.nome.substr(0,50)))])],2)]}}],null,false,588393005),model:{value:(_vm.equipeSel),callback:function ($$v) {_vm.equipeSel=$$v},expression:"equipeSel"}})],1):_vm._e(),_c('v-col',{staticClass:"my-1 py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"fw-300 fs-10pt f-roboto my-0 pb-1 ps-1"},[_vm._v("Público")]),_c('treeselect',{attrs:{"multiple":"","options":_vm.vetPublico,"placeholder":"Selecione público"},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_c('span',{staticClass:"fs-10pt fw-300 truncate"},[_vm._v(_vm._s(node.raw.label))])])}},{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_c('span',{staticClass:"fs-7pt fw-300 truncate"},[_vm._v(_vm._s(node.raw.label.substr(0,80)))])])}}]),model:{value:(_vm.publicoSel),callback:function ($$v) {_vm.publicoSel=$$v},expression:"publicoSel"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mt-0 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":_vm.cor.active.S,"small":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" cancelar ")]),_c('v-btn',{directives:[{name:"c",rawName:"v-c:B",arg:"B"}],attrs:{"color":_vm.cor.active.V,"small":""},on:{"click":_vm.update}},[_c('i',{directives:[{name:"i",rawName:"v-i:duo#check-circle#18",arg:"duo#check-circle#18"}],staticClass:"btn-icon-left"}),_vm._v(" "+_vm._s(_vm.btnName)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }