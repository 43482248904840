<template>
  <div class="row m-0 p-0">
    <div class="col-12">
      <v-card outlined class="mt-1">
        <v-card-title class="my-0 py-1">
          <p class="ms-1 my-0 fs-11pt f-raleway fw-700" v-c:P>
            <i v-i:duo#gear#18 v-c:P></i>
            Manutenção
          </p>
        </v-card-title>
        <hr class="my-0 py-0 mx-2">
        <v-card-text class="">
          <v-btn class="mb2 ml-2" color="warning" x-small @click="CriaEquipesCalendario">CriaEquipesCalendario</v-btn>
          <v-btn class="mb2 ml-2" color="warning" x-small @click="CorrigeEquipesCalendario">CorrigeEquipesCalendario</v-btn>
          <hr>
          <v-btn class="mb2" color="primary" x-small @click="parseResp">Parse Responsáveis</v-btn>
          <v-btn class="mb2 ml-2" color="primary" x-small @click="parseEventos">Parse Eventos</v-btn>
          <v-btn class="mb2 ml-2" color="primary" x-small @click="parsePessoas">Parse Pessoas</v-btn>
          <hr>
          <v-btn class="mb2 ml-2" color="error" x-small @click="pushSetores">Push Setores</v-btn>
          <hr>
          Dados de importação
          <v-file-input show-size label="Planilha" @change="parseExcelFile1"></v-file-input>
          <div class="mb2" v-if="abas.length > 0">
            <p>Abas:</p>
            <v-btn class="mr2" color="primary" x-small v-for="(item,index) in abas" :key="index" @click="parseAba(index)">{{item}}</v-btn>
          </div>
          <div id="result1">
            <p v-if="linhas != null">
              linhas ({{abaSelecionada}}) [{{linhas.length}}]:
              <VueObjectView v-model="linhas" :key="refreshKey"/>
            </p>
          </div>

        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js'
import { notification } from '@/components/notification/notification.js'
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'
import XLSX from 'xlsx';
import VueObjectView from 'vue-object-view';
import { setores, pessoas } from './dados.js'

export default {
  name: 'admincalendario',
  components: { dummy, VueObjectView },
  computed: {
  },
  data () {
    return {
      tab: null,
      abas: [],
      abaSelecionada: "",
      linhas: null,
      pessoasdb: [],
      setoresdb: [],
      eventosdb: {},
      equipesCaldb: {},
    }
  },
  created() {
    this.log_s('Created');
  },
  mounted() {
    var self = this;
    this.log_s('Mounted');
    rdb.ref('/pessoas').once('value', function(snapshot) {
      var ret = snapshotValToArray(snapshot.val());
      self.pessoasdb = ret;
      console.log("self.pessoasdb",self.pessoasdb);
    });
    rdb.ref('/usuarios/setores').once('value', function(snapshot) {
      var ret = snapshotValToArray(snapshot.val());
      self.setoresdb = ret;
      console.log("self.setoresdb",self.setoresdb);
    });
    rdb.ref('/calendarios/2021S2/eventos').once('value', function(snapshot) {
      self.eventosdb = snapshot.val();
      console.log("self.eventosdb",self.eventosdb);
    });
    rdb.ref('/calendarios/2021S2/equipes').once('value', function(snapshot) {
      self.equipesCaldb = snapshot.val();
      console.log("self.equipesCaldb",self.equipesCaldb);
    });

  },
  methods: {

    parseXLSX(abaIdx) {
      var arrayBuffer = this.reader.result;
      var options = { type: 'array' };
      this.workbook = XLSX.read(arrayBuffer, options);
      console.timeEnd();
      var sheetName = this.workbook.SheetNames
      this.abas = sheetName;
      console.log(sheetName);
      var sheet = this.workbook.Sheets[sheetName[abaIdx]]
      this.abaSelecionada = sheetName[0];
      console.log(sheet);
      this.linhas = XLSX.utils.sheet_to_json(sheet, {raw: false});
      //this.linhas = XLSX.utils.sheet_to_json(sheet);
      console.log(this.linhas);
      this.refreshKey += 1;
      this.$forceUpdate();
    },

    parseExcelFile1(file) {
      var self = this;
      console.log("parseExcelFile1",file);
      if(file == null) return;
      console.time();
      this.reader = new FileReader();
      this.reader.onloadend = function(event) {
        self.parseXLSX(0);
      };
      this.reader.readAsArrayBuffer(file);
    },

    parseAba(abaIdx) {
      this.parseXLSX(abaIdx)
      this.$forceUpdate();
    },

    searchInArrayObj(array, key, value){
      for (var i=0; i < array.length; i++) {
        if (array[i][key].trim().toLowerCase() === value.trim().toLowerCase()) {
          return i;
        }
      }
      return -1;
    },

    parseResp() {
      console.log("parseResp");
      var setores = [];
      var pessoas = [];
      for(var i=0; i<this.linhas.length;i++) {
        var vetSetores = this.linhas[i].respSetor.split(";");
        for(var k=0; k<vetSetores.length;k++) {
          var setor = vetSetores[k].trim();
          var pos = this.searchInArrayObj(setores,"nome",setor);
          if(pos == -1) {
            var vetPessoas = this.linhas[i].gestores.split(";");
            for(var idx in vetPessoas) {
              vetPessoas[idx] = vetPessoas[idx].trim()
            }
            var item = { nome: setor, responsaveis: vetPessoas };
            setores.push(item);
          }

        }
      }
      for(var idx in setores) {
        for(var idx2 in setores[idx].responsaveis) {
          var pos = pessoas.indexOf(setores[idx].responsaveis[idx2]);
          if(pos == -1) {
            pessoas.push(setores[idx].responsaveis[idx2]);
          }
        }
      }
      console.log("setores",setores);
      console.log("pessoas",pessoas);
      console.log("setores",JSON.stringify(setores));
      console.log("pessoas",JSON.stringify(pessoas));
    },

    pushSetores() {
      console.log("pushSetores");
      console.log(setores);
    },

    parseEventos() {
      console.log("parseEventos");
    },

    parsePessoas() {
      var vetPessoas = [];
      //this.setoresdb = [];
      //console.log("this.setoresdb",this.setoresdb);
      //for(var idx in setores) {
      //  var setor = setores[idx];
      //  setor.id = generatePushID()();
      //  delete setor.responsaveis;
      //  this.setoresdb.push(setor);
      //}
      //var idEducadores = generatePushID()();
      //this.setoresdb.push({ nome: "Educadores", id: idEducadores });
      console.log("this.setoresdb",this.setoresdb);

      for(var i=0; i<this.linhas.length;i++) {
        var item = Object.assign({}, this.linhas[i]);
        item.email = item["e-mail"];
        delete item["e-mail"];
        item.equipes = {};
        if(item.Equipe == undefined) {
          item.Equipe = "Educadores";
        }
        if(item.Equipe == "Estratégia") {
          item.Equipe = "Estratégia & Integração";
        }
        if(item.Equipe == "Avaliação") {
          item.Equipe = "Avaliação & Desempenho";
        }
        var idsetores = this.searchInArrayObj(this.setoresdb,"nome",item.Equipe);
        if(idsetores == -1) {
          console.log(item.Equipe);
          return;
        }
        item.equipes[this.setoresdb[idsetores].id] = Object.assign({}, this.setoresdb[idsetores]);
        var setor = item.setores;
        item.setores = {};
        idsetores = this.searchInArrayObj(this.setoresdb,"nome",setor);
        item.setores[this.setoresdb[idsetores].id] = Object.assign({}, this.setoresdb[idsetores]);
        delete item.Equipe;
        item.cargo = "Educador(a)";
        item.formacao = { area: "não informado", titulacao: "não informado" };
        item.grandeArea = 0;
        item.ies = "não informado";
        item.regional = "SP";
        item.senha = item.nome.substr(0,4) + "1234";
        item.unidade = "não informado";
        item.urlCapa = "https://placeimg.com/1200/200/nature";
        item.urlFoto = "https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/dummy-profiles%2Fdummy-profile.png?alt=media&token=6f00630a-6802-400c-9a2f-4bc23c9c8848";
        vetPessoas.push(item);
      }

      for(var i=0; i<this.pessoasdb.length;i++) {
        var item = Object.assign({}, this.pessoasdb[i]);
        var areas = item.Areas.split(";");
        var idAreas = generatePushID()();
        item.areasIC = {};
        for(var idx in areas) {
          var idarea = generatePushID()();
          item.areasIC[idarea] = { id: idarea, sigla: areas[idx] };
        }
        delete item.Areas;
        delete item.Sigla;
        item.equipes = {};
        var id = generatePushID()();
        if(item.Equipe == undefined) {
          item.Equipe = "Educadores";
        }
        if(item.Equipe == "Estratégia") {
          item.Equipe = "Estratégia & Integração";
        }
        if(item.Equipe == "Avaliação") {
          item.Equipe = "Avaliação & Desempenho";
        }
        item.setores = {};
        var idsetores = this.searchInArrayObj(this.setoresdb,"nome",item.Equipe);
        if(idsetores == -1) {
          console.log(item.Equipe);
          return;
        }
        item.equipes[this.setoresdb[idsetores].id] = Object.assign({}, this.setoresdb[idsetores]);
        item.setores[this.setoresdb[idsetores].id] = Object.assign({}, this.setoresdb[idsetores]);
        delete item.Equipe;
        item.ies = item.Marca,
        delete item.Marca;
        item.nome = item.NOME,
        delete item.NOME;
        item.grandeArea = item.GrandeArea,
        delete item.GrandeArea;
        item.cargo = item.Cargo;
        delete item.Cargo;
        //equipes: {},
        //setores: {},
        item.formacao = { area: item.Formacao, titulacao: item.Titulacao };
        item.regional = item.Regional;
        item.telefone = item.Telefone;
        item.unidade = item.Unidade;
        delete item.Regional;
        delete item.Telefone;
        delete item.Unidade;
        delete item.Formacao;
        delete item.Titulacao;
        if(item.urlCapa == undefined) {
          item.urlCapa = "https://placeimg.com/1200/200/nature";
        }
        if(item.senha == undefined) {
          item.senha = "1234";
        }
        vetPessoas.push(item);
      }


      console.log(vetPessoas);

      var updates = {};

      //for(var idxS in this.setoresdb) {
      //  var newKey = rdb.ref('/usuarios/setores/').push().key;
      //  var elem = this.setoresdb[idxS];
      //  elem.id = newKey;
      //  updates["/usuarios/setores/"+newKey] = elem;
      //}

      var pessoasOk = [];
      var pessoasRepet = [];

      for(var idxU in vetPessoas) {
        var pos = this.searchInArrayObj(pessoasOk,"nome",vetPessoas[idxU].nome);
        if(pos == -1) {
          pessoasOk.push(vetPessoas[idxU]);
        } else {
          pessoasRepet.push(vetPessoas[idxU]);
        }
      }
      console.log("pessoasOk",pessoasOk);
      console.log("pessoasRepet",pessoasRepet);

      for(var idxU in pessoasOk) {
        var newKey = rdb.ref('/usuarios/pessoas/').push().key;
        var elem = pessoasOk[idxU];
        elem.id = newKey;
        updates["/usuarios/pessoas/"+newKey] = elem;
      }
      console.log(updates);
      rdb.ref().update(updates);
      console.log("Agora já elvis!");
    },


    CriaEquipesCalendario() {
      var self = this;
      console.log("CriaEquipesCalendario");
      var equipes = {};
      var equipes2 = {};
      for(var key in self.eventosdb) {
        for(var keyEquipe in self.eventosdb[key].equipes) {
          var equipe = self.eventosdb[key].equipes[keyEquipe];
          equipes[equipe.nome] = self.eventosdb[key].equipes[keyEquipe];
        }
      }
      for(var key in equipes) {
        equipes2[equipes[key].id] = equipes[key];
      }
      console.log("qtde equipes ",Object.keys(equipes2).length);
      console.log("equipes",equipes2);

      var updates = {};
      updates["/calendarios/2021S2/equipes"] = equipes2;
      console.log(updates);
      rdb.ref().update(updates);
      console.log("Agora já elvis!");

    },

    CorrigeEquipesCalendario() {
      var self = this;
      console.log("CorrigeEquipesCalendario");
      var equipes = {};
      var equipes2 = {};

      for(var key in self.equipesCaldb) {
        equipes2[self.equipesCaldb[key].nome] = self.equipesCaldb[key];
      }
      console.log("qtde equipes2 ",Object.keys(equipes2).length);
      console.log("equipes2",equipes2);

      for(var key in self.eventosdb) {
        var equipes = {};
        for(var keyEquipe in self.eventosdb[key].equipes) {
          var equipe = equipes2[self.eventosdb[key].equipes[keyEquipe].nome];
          equipes[equipe.id] = equipe;
        }
        self.eventosdb[key].equipes = equipes;
      }
      console.log("self.eventosdb",self.eventosdb);

      var updates = {};
      updates["/calendarios/2021S2/eventos"] = self.eventosdb;
      console.log(updates);
      rdb.ref().update(updates);
      console.log("Agora já elvis!");

    }

  }
}
</script>
