<template>
  <v-row>
    <v-col cols="12">
      <admintopo />
      <v-row class="mt-5 mb-0 py-0">
        <v-col cols="12" class="mt-0 pt-0">
          <v-tabs v-bg:B class="mt-0 pb-0 pt-1 px-2 mb-0 rounded" height="26" v-model="tab" background-color="transparent" :color="cor.active.P">
            <v-tab class="mt-0 mb-0" style="">
              <div class="f-raleway mb-3" style="max-height: 20px; text-transform: none; letter-spacing: normal;">
                <i class="btn-icon-left me-0" v-i:duo#calendar#20 v-c:P></i>
                Calendários
              </div>
            </v-tab>
            <v-tab class="ms-3 mt-0" style="max-height: 20px; padding: 0px;">
              <div v-if="adminCalendario" class="f-raleway mb-3" style="max-height: 20px; text-transform: none; letter-spacing: normal;">
                <i class="btn-icon-left me-0" v-i:duo#gear#20 v-c:P></i>
                Administração 2022/1
              </div>
            </v-tab>
            <v-tab class="ms-3 mt-0" style="max-height: 20px; padding: 0px;">
              <div v-if="adminCalendario" class="f-raleway mb-3" style="max-height: 20px; text-transform: none; letter-spacing: normal;">
                <i class="btn-icon-left me-0" v-i:duo#gear#20 v-c:P></i>
                Administração 2021/2
              </div>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" v-bg:b class="mt-0 pt-0">
            <v-tab-item class="m-0 p-0">
              <calendariotabs />
            </v-tab-item>
            <v-tab-item class="m-0 p-0">
              <calendarioadmin2022s1 :key="0"/>
            </v-tab-item>
            <v-tab-item class="m-0 p-0">
              <calendarioadmin />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { notification } from '@/components/notification/notification.js'
import dummy from '@/components/dummy/dummy.vue'
import { eventBus } from "@/main.js";
import { faker } from '@/components/dummy/faker.js'
import calendariotabs from "@/views/calendarios/calendarioTabs.vue"
import admintopo from "./admin-topo.vue";
import calendarioadmin from "./calendario-admin.vue";
import calendarioadmin2022s1 from "./admin2022S1/calendario-admin.vue";

export default {
  name: 'calendarioHome',
  components: { dummy, admintopo, calendariotabs, calendarioadmin, calendarioadmin2022s1 },
  computed: {
    adminCalendario() {
      if(this.userLog.equipes != undefined) {
        if(this.userLog.equipes["-MdK4fS4aV2BIyM728_E"] != undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
      //var equipe = this.$store.state.admin.equipesByName["Administradores do Calendário"];
      //return (equipe.pessoas[this.userLog.id] != undefined);
    }
  },
  data () {
    return {
      tab: null,
      tab2: null,
      equipe: {},
    }
  },
  mounted() {
    var self = this;
    this.log_s('Mounted');
    rdb.ref('/usuarios/equipes/-MdK4fS4aV2BIyM728_E').on('value', function(snapshot) {
      self.equipe = snapshot.val();
      //console.log("self.equipe",self.equipe);
    });
  },
  methods: {

  }
}
</script>
