<template>
  <v-card outlined class="mt-0 mx-1" :color="cor.active.b">
    <v-tabs class="mt-2 mx-2" height="24" :color="cor.active.I" v-model="tab2" :background-color="cor.active.b">
      <v-tab class="" style="">
        <div class="f-sanspro fs-11pt fw-600 m-0 p-0" style="max-height: 20px; text-transform: none; letter-spacing: normal;">
          Alunos
        </div>
      </v-tab>
      <v-tab class="mt-0" style="">
        <div class="f-sanspro fs-11pt fw-600 m-0 p-0" style="max-height: 20px; text-transform: none; letter-spacing: normal;">
          Professores
        </div>
      </v-tab>
      <v-tab class="mt-0" style="">
        <div class="f-sanspro fs-11pt fw-600 m-0 p-0" style="max-height: 20px; text-transform: none; letter-spacing: normal;">
          VPA
        </div>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab2" v-bg:b class="mt-0 pt-0">
      <v-tab-item class="mt-0 pt-0">
        <v-card outlined class="mx-2 mt-2">
          <appcalendario route="alunos"/>
        </v-card>
      </v-tab-item>
      <v-tab-item class="m-0 p-0">
        <v-card outlined class="mx-2 mt-2">
          <appcalendario route="professores"/>
        </v-card>
      </v-tab-item>
      <v-tab-item class="m-0 p-0">
        <v-card outlined class="mx-2 mt-2">
          <appcalendario route="VPA"/>
        </v-card>
      </v-tab-item>
      <v-tab-item class="m-0 p-0">
        <v-card outlined class="mx-2 mt-2">
          <appcalendario route="diretores"/>
        </v-card>
      </v-tab-item>
      <v-tab-item class="m-0 p-0">
        <v-card outlined class="mx-2 mt-2">
          <appcalendario route="coordenador"/>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
// @ is an alias to /src
import { eventBus } from "@/main.js";
import appcalendario from "@/views/appCalendario/appCalendario2.vue"

export default {
  name: 'calendariotabs',
  components: { appcalendario },
  computed: {
    adminCalendario() {
      var equipe = this.$store.state.admin.equipesByName["Administradores do Calendário"];
      return (equipe.pessoas[this.userLog.id] != undefined);
    }
  },
  data () {
    return {
      tab: null,
      tab2: null,
    }
  },
  methods: {

  }
}
</script>
