<template>
  <v-card class="py-2 px-3" v-if="title != undefined">
    <div class="">
      <p class="ms-0 my-0 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
        <i class="btn-icon-left" v-i:duo#users#18></i>
        Calandário <span class="f-lato fs-9pt fw-800" v-c:K>{{title.toUpperCase()}}</span>
      </p>
    </div>
    <v-card class="py-1 px-2 mt-3" v-bg:b#4 @click="$emit('cardClick')">
      <p class="ms-0 my-0 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
        <i class="btn-icon-left" v-i:duo#users#16></i>
        <span class="">Acessos hoje: </span>
        <span class="ms-1 f-lato fw-800">{{$moment().format("DD")}} de {{$moment().format("MMMM")}} de {{$moment().format("YYYY")}}</span>
      </p>
      <p class="ms-0 mt-2 mb-1 f-raleway fw-500 fs-10pt" v-cHex="hexShades(cor.active.B,0.5)">
        <span class="fs-13pt fw-800 f-roboto" :key="refresh">{{ultimosDias[5]}}</span>
        <span class="ms-1 fw-400">acessos</span>
      </p>
    </v-card>
    <div class="mt-3">
      <p class="ms-0 my-0 f-sanspro fw-400 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
        <span class="fw-600 fs-11pt">Acessos</span> (total de seis dias)
        <span class="ms-2 border px-1 rounded f-roboto fw-500 fs-13pt" v-cHex="hexShades(cor.active.B,1)">
          {{pessoasTotal}}
        </span>
      </p>
      <hr class="mb-2 mt-1">
      <v-row class="p-0 mx-1 mt-1 mb-0">
        <v-col cols="2" class="m-0 p-0" v-for="idx in [0,1,2,3,4,5]" :key="idx">
          <span class="fs-7pt f-roboto fw-800">{{labelDias[idx]}}</span>
          <span class="ms-1 fs-7pt f-sanspro fw-300">{{labelDiasSem[idx].toUpperCase()}}</span>
        </v-col>
      </v-row>
      <v-sheet color="transparent" class="mt-n3">
        <v-sparkline :value="ultimosDias" label-size="12" :color="cor.active.I" height="80" padding="14" auto-draw smooth>
          <template v-slot:label="item">
            {{item.value}}
          </template>
        </v-sparkline>
      </v-sheet>
      <div class="ms-0 mt-3 mb-1 text-right f-raleway fw-500 fs-12pt" v-cHex="hexShades(cor.active.B,0.5)">
        <v-btn @click="$emit('cardClick')" class="text-nonecase f-sanspro fw-400 fs-8pt" v-c:I outlined x-small>
          ver detalhes
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import moment from "moment";

export default {
  name: "cardacessos",
  components: {},
  props: [ "title" ],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      pessoasOnline: [],
      pessoasTotal: 0,
      ultimosDias: [ 0, 0, 0, 0, 0, 0, 0 ],
      labelDias: [ "", "", "", "", "", "" ],
      labelDiasSem: [ "", "", "", "", "", "" ],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;

    var setedias = moment().subtract(6,"days").format("YYYY-MM-DDTHH:mm");
    rdb.ref('/calendarios/2021S2/backlogs/'+this.title).orderByChild("createdAt").startAfter(setedias).on('value',function(snapshot) {
      var values = snapshot.val();
      //console.log("values",values);
      var vet = [];
      self.pessoasTotal = 0;
      self.ultimosDias = [ 0, 0, 0, 0, 0, 0 ];
      for(var i=0; i<6;i++) {
        self.labelDias[i] = moment().subtract(5-i,"days").format("DD");
        self.labelDiasSem[i] = moment().subtract(5-i,"days").format("ddd");
      }
      for(var key in values) {
        self.pessoasTotal++;
        var time = values[key].createdAt;
        var dias = 5-moment().diff(time,"days");
        self.ultimosDias[dias]++;
        vet.push(values[key]);
      }
      console.log("vet",vet);
      console.log("self.ultimosDias",self.ultimosDias);
    });

  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
