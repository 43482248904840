<template>
  <div class="row mt-1">
    <div class="col-12 col-lg-12 mt-0">
      <v-card class="mb-0 pb-0 mt-0 pt-0">
        <v-card-text class="my-0 py-0">
          <v-row class="mb-0 pt-2 pb-0">
            <v-col class="py-0 mb-0" cols="12" md="4" lg="3">
              <p class="fs-12pt pt-2 pb-0 mb-0 fw-400 f-yanone text-left">
                <v-icon class="fs-15pt">mdi-account-multiple</v-icon>
                Lista de equipes CALENDÁRIO
              </p>
            </v-col>
            <v-col class="py-0 mb-0 text-right" cols="12" md="8" lg="9">
              <v-btn :color="cor.active.S" x-small @click="$refs.formequiperef.show(null)">
                <i class="btn-icon-left" v-i:duo#plus-circle#18></i>
                nova equipe
              </v-btn>
            </v-col>
          </v-row>
          <!-- <p><VueObjectView v-model="value" :key="refreshKey"/></p> -->

          <div v-if="loading" class="mt-4">
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
          </div>

          <v-alert v-if="!loading && equipes.length == 0" class="mt-4" outlined color="grey" type="warning" prominent border="left">
            <p class="" style="color: black;">
              Nenhum resultado encontrado.
            </p>
          </v-alert>

          <hr class="mt-4 mb-2">

          <v-list>
            <div class="" v-for="equipe in equipes">
              <v-list-item class="mb-0 pb-0 mt-n2">
                <v-list-item-avatar size="52" class="mt-0 me-0 pe-0">
                  <v-avatar size="52">
                    <i v-i:duo#users#26></i>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content class="text-left ps-0 ms-0">
                  <v-list-item-title class="ms-0 ps-0 my-0 py-0">
                    <p class="my-0 py-0 fs-11pt f-raleway">
                      <v-tooltip bottom v-for="(user,index) in equipe.pessoas" :key="index">
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar v-bind="attrs" v-on="on" class="" style="margin-right: -24px;">
                            <img class="sz30" :src="user.urlFoto" alt="foto">
                          </v-avatar>
                        </template>
                        <span class="fs-9pt f-roboto">{{user.nome}}</span>
                      </v-tooltip>
                      <span class="ps-2 ms-4 fw-500 fs-10pt f-lato">{{equipe.nome}}</span>
                      <span v-c:I v-bg:S class="ms-2 f-roboto fs-10pt rounded px-1">
                        {{Object.keys(equipe.pessoas).length}} pessoas
                      </span>
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon  class="px-0 mx-0">
                  <v-btn class="px-0 mx-0" :color="cor.active.D" small icon @click="$refs.promptdialogref.show(equipe)">
                    <i class="btn-icon-left ps-1" v-i:duo#trash#20></i>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-icon  class="px-0 mx-0 ms-1">
                  <v-btn class="px-0 mx-0" :color="cor.active.I" small icon @click="formShow(equipe)">
                    <i class="btn-icon-left ps-1" v-i:duo#pencil#20></i>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
              <v-divider class="mt-n1 mb-n1"></v-divider>
            </div>
          </v-list>

        </v-card-text>
      </v-card>
    </div>

    <formequipe ref="formequiperef" @update="formUpdate"/>
    <promptdialog ref="promptdialogref" @confirm="confirmaRemover" />

  </div>

</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import formequipe from "./formEquipe.vue";
import promptdialog from "./promptdialog.vue";

export default {
  name: "equipes",
  components: { formequipe, promptdialog },
  data(){
    return {
      loading: false,
      equipes: [],
      equipeParaAtualizar: {},
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    rdb.ref('/calendarios/2021S2/equipes').on('value', function(snapshot) {
      self.equipes = Object.assign({}, snapshot.val());
      for(var key in self.equipes) {
        for(var keyUser in self.equipes[key].pessoas) {
          //console.log(keyUser);
          rdb.ref('/usuarios/pessoas/'+keyUser).once('value', function(snapshot) {
            self.equipes[key].pessoas[keyUser] = Object.assign({}, snapshot.val());
          });
        }
      }
      self.loading = false;
      console.log(self.equipes);
    });
  },

  methods:{

    formShow(item) {
      const equipe = clone2(item);
      this.equipeParaAtualizar = item;
      this.$refs.formequiperef.show(equipe);
    },

    formUpdate(dados) {
      console.log("dados",dados);
      var updates = {};
      var elem = clone2(dados.formData);
      if(dados.tipo == "Atualizar") {
        updates["/calendarios/2021S2/equipes/"+elem.id] = elem;
        console.log("this.equipeParaAtualizar",this.equipeParaAtualizar);
      } else {
        var newKey = rdb.ref('/calendarios/2021S2/equipes/').push().key;
        elem.id = newKey;
        updates["/calendarios/2021S2/equipes/"+newKey] = elem;
      }
      console.log(updates);
      rdb.ref().update(updates);
      this.toastS("Dados cadastrados com sucesso!");
      console.log("agora já elvis!");
    },

    confirmaRemover(item) {
      console.log("confirmaRemover",item);
      var elem = clone2(item);
      console.log("confirmaRemover",elem);
      var updates = {};
      updates["/calendarios/2021S2/equipes/"+elem.id] = null;
      console.log(updates);
      rdb.ref().update(updates);
      this.toastS("Item removido com sucesso!");
      console.log("agora já elvis!");
    }

  }
}
</script>

<style scoped>

</style>
