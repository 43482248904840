<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card class="pb-2">
      <v-card-title class="fs-13pt my-0 pb-1" v-c:I>
        <span class="fw-700 f-raleway">{{btnName}}</span>
        <span class="ps-1 fw-300 f-raleway"> evento</span>
      </v-card-title>
      <v-divider class="mt-0 mb-1 py-0"></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Tema do evento</p>
              <v-text-field v-model="formData.tema" :rules="[rules.required]" solo dense label="Tema do evento" required placeholder="Digite o tema do evento"></v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Descrição do evento</p>
              <v-text-field v-model="formData.descricao" :rules="[rules.required]" solo dense label="Descrição do evento" required placeholder="Digite a descrição do evento"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Data de Início</p>
              <v-menu ref="menu1" v-model="menuDataInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="dataInicioFormated" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="formData.dataInicio" no-title @change="menuDataInicio = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="6" class="my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Data de Término</p>
              <v-menu ref="menu1" v-model="menuDataFinal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="dataFinalFormated" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="formData.dataFinal" no-title @change="menuDataFinal = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" class="mt-0 mb-1 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Áreas</p>
              <treeselect v-model="setorSel" multiple :normalizer="normalizer" :options="vetSetores" placeholder="Selecionar áreas">
                <div slot="option-label" slot-scope="{ node }">
                  <span class="fs-10pt fw-300 truncate">{{node.raw.nome}}</span>
                </div>
                <div slot="value-label" slot-scope="{ node }">
                  <span class="fs-7pt fw-300 truncate">{{node.raw.nome.substr(0,30)}}</span>
                </div>
              </treeselect>
            </v-col>
            <v-col cols="12" class="my-1 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Equipe</p>
              <v-autocomplete :key="refresh" class="my-0 pt-2 ms-0" dense solo v-model="equipeSel" :items="vetEquipes" chips color="blue-grey lighten-2" label="Selecione equipe">
                <template v-slot:item="data" class="mt-0 py-0" style="">
                  <div class="my-0 py-0" style="max-width: 400px;">
                    <i class="left" v-i:duo#users#16></i>
                    <p class="left fw-300 f-lato fs-8pt ms-2 mt-1 py-0">
                      <span class="">{{data.item.nome.substr(0,75)}}</span>
                    </p>
                  </div>
                </template>
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="equipeSel = []">
                    <v-tooltip bottom v-for="(user,index) in data.item.pessoas" :key="index">
                      <template v-slot:activator="{ on, attrs }" class="left">
                        <v-avatar v-bind="attrs" v-on="on" class="" style="margin-right: -4px;">
                          <img class="sz30" :src="user.urlFoto" alt="foto">
                        </v-avatar>
                      </template>
                      <span>{{user.nome}}</span>
                    </v-tooltip>
                    <span class="ms-3 fs-8pt">{{ data.item.nome.substr(0,50) }}</span>
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="my-1 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Público</p>
              <treeselect v-model="publicoSel" multiple :options="vetPublico" placeholder="Selecione público">
                <div slot="option-label" slot-scope="{ node }">
                  <span class="fs-10pt fw-300 truncate">{{node.raw.label}}</span>
                </div>
                <div slot="value-label" slot-scope="{ node }">
                  <span class="fs-7pt fw-300 truncate">{{node.raw.label.substr(0,80)}}</span>
                </div>
              </treeselect>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn :color="cor.active.S" small @click="dialog = false">
          cancelar
        </v-btn>
        <v-btn :color="cor.active.V" v-c:B small @click="update">
          <i class="btn-icon-left" v-i:duo#check-circle#18></i>
          {{btnName}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import moment from "moment";

export default {
  name: "formevento",
  components: { },
  data() {
    return {
      menuDataInicio: false,
      menuDataFinal: false,
      refresh: 0,
      dialog: false,
      loading: false,
      btnName: "Adicionar",
      formData : { nome: "", ano: "", dataFinal: "", dataInicio: "", equipes: {}, setores: {}, tipo: "termino", pessoas: {} },
      rules: {
        required: value => !!value || 'Obrigatório',
      },
      setorSel: null,
      vetSetores: [],
      setores: {},
      equipeSel: null,
      vetEquipes: [],
      publicoSel: null,
      vetPublico: [
        { label: "VPA", id: "VPA" },
        { label: "diretores", id: "diretores" },
        { label: "coordenador", id: "coordenador" },
        { label: "professores", id: "professores" },
        { label: "alunos", id: "alunos" },
      ],
    }
  },
  computed: {
    dataInicioFormated () {
      return this.formData.dataInicio ? moment(this.formData.dataInicio).format('DD/MM/YYYY') : ''
    },
    dataFinalFormated () {
      return this.formData.dataFinal ? moment(this.formData.dataFinal).format('DD/MM/YYYY') : ''
    },
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    rdb.ref('/calendarios/2021S2/equipes').on('value', function(snapshot) {
      self.vetEquipes = snapshotValToArray(snapshot.val());
      console.log("self.vetEquipes",self.vetEquipes);
      rdb.ref('/usuarios/setores').on('value', function(snapshot) {
        self.vetSetores = snapshotValToArray(snapshot.val());
        self.setores = snapshot.val();
        console.log("self.setores",self.setores);
        self.loading = false;
      });
    });

    window.moment = moment;
  },

  methods:{

    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    normalizer(node) {
      return {
        id: node.id,
        label: node.nome,
      }
    },

    show(dados) {
      var self = this;
      console.log("dados",dados);
      this.dialog = true;
      this.refresh++;
      this.$forceUpdate();
      if(dados == null) {
        this.formData = { nome: "", ano: "", dataFinal: "", dataInicio: "", equipes: {}, setores: {}, tipo: "termino", pessoas: {} };
        this.btnName = "Adicionar";
      } else {
        this.formData = clone2(dados);
        this.equipeSel = null;
        for(var key in dados.equipes) {
          this.equipeSel = dados.equipes[key];
          break;
        }
        this.setorSel = [];
        for(var key in dados.setores) {
          this.setorSel.push(dados.setores[key].id);
        }
        this.publicoSel = [];
        for(var key in self.vetPublico) {
          if(dados[self.vetPublico[key].label] != undefined) {
            this.publicoSel.push(self.vetPublico[key].label);
          }
        }
        this.btnName = "Atualizar";
      }
      console.log("this.formData",this.formData);
    },

    changeSelValue(value) {
      console.log("changeSelValue",value);
      this.formData.pessoas = {};
      for(var key in value) {
        this.formData.pessoas[value[key].id] = value[key];
      }
    },

    update() {
      console.log("setorSel",this.setorSel);
      console.log("equipeSel",this.equipeSel);
      console.log("this.publicoSel",this.publicoSel);
      console.log("this.formData.DataInicio",this.formData.dataInicio);
      console.log("this.formData.dataFinal",this.formData.dataFinal);

      if(this.formData.tema == "") {
        this.toastD("Tema obrigatório!");
        return;
      }
      if(this.formData.descricao == "") {
        this.toastD("Descrição obrigatória!");
        return;
      }
      if(this.formData.dataInicio == "") {
        this.toastD("Datas obrigatórias!");
        return;
      }
      if(this.formData.dataFinal == "") {
        this.toastD("Datas obrigatórias!");
        return;
      }
      if(this.setorSel == null) {
        this.toastD("Área obrigatória!");
        return;
      }
      if(this.equipeSel == null) {
        this.toastD("Equipe obrigatória!");
        return;
      }
      if(this.publicoSel == null || this.publicoSel.length == 0) {
        this.toastD("Público obrigatório!");
        return;
      }
      var formData = clone(this.formData);
      formData.equipes = {};
      formData.equipes[this.equipeSel.id] = this.equipeSel;
      formData.dataFinal = moment(formData.dataFinal).format();
      formData.dataInicio = moment(formData.dataInicio).format();
      formData.mesano = moment(formData.dataFinal).format("MMMM").substr(0,3)+"/"+moment(formData.dataFinal).format("YY");
      formData.ano = parseInt(moment(formData.dataFinal).format("YYYY"));
      formData.mes2 = parseInt(moment(formData.dataFinal).format("MM"));
      formData.diaInicioEvento = parseInt(moment(formData.dataInicio).format("DD"));
      formData.diaFinalEvento = parseInt(moment(formData.dataFinal).format("DD"));
      formData.mes2 = parseInt(moment(formData.dataFinal).format("MM"));
      var setor = clone(this.setores[this.setorSel[0]]);
      formData.setor = setor.nome;
      formData.setores = {};
      formData.setores[setor.id] = setor;
      for(var idx in this.publicoSel) {
        formData[this.publicoSel[idx]] = 'x';
      }
      delete formData.pessoas;
      delete formData.nome;
      this.dialog = false;
      console.log(formData);
      this.$emit("update",{ formData: formData, tipo: this.btnName } );
    }

  }
}
</script>

<style scoped>

</style>
