<template>
  <v-card outlined class="mt-1" :color="cor.active.b">
    <v-card-text class="" class="mt-0 pt-0">
      <v-tabs class="my-2" height="24" :color="cor.active.I" v-model="tab2" :background-color="cor.active.b">
        <v-tab class="" style="">
          <div class="f-sanspro fs-11pt fw-600 m-0 p-0" style="max-height: 20px; text-transform: none; letter-spacing: normal;">
            Acessos
          </div>
        </v-tab>
        <v-tab class="" style="">
          <div class="f-sanspro fs-11pt fw-600 m-0 p-0" style="max-height: 20px; text-transform: none; letter-spacing: normal;">
            Áreas (Setores)
          </div>
        </v-tab>
        <v-tab class="" style="">
          <div class="f-sanspro fs-11pt fw-600 m-0 p-0" style="max-height: 20px; text-transform: none; letter-spacing: normal;">
            Eventos
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab2" class="mt-2" v-bg:b>
        <v-tab-item class="mt-0 pt-0">
          <v-row class="mt-n1">
            <v-col cols="12" md="6" lg="3" class="m-0">
              <cardacessos title="professores" />
            </v-col>
            <v-col cols="12" md="6" lg="3" class="m-0">
              <cardacessos title="alunos" />
            </v-col>
            <v-col cols="12" md="6" lg="3" class="m-0">
              <cardacessos title="VPA" />
            </v-col>
            <v-col cols="12" md="6" lg="3" class="m-0">
              <cardacessos title="diretores" />
            </v-col>
            <v-col cols="12" md="6" lg="3" class="m-0">
              <cardacessos title="coordenador" />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="">
          <setores />
        </v-tab-item>
        <v-tab-item class="">
          <listeventos />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import listeventos from './listEventos.vue'
import setores from './setores.vue'
import listpublicos from './listPublicos.vue'
import cardacessos from "./cards/card-acessos.vue";

export default {
  name: "",
  components: { listeventos, setores, listpublicos, cardacessos },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      tab2: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
