<template>
  <div class="row mt-1">
    <div class="col-12 col-lg-12 mt-0">
      <v-card class="mb-0 pb-0 mt-0 pt-0">
        <v-card-text class="my-0 py-0">
          <v-row class="mb-0 pt-2 pb-0">
            <v-col class="py-0 mb-0" cols="12" md="4" lg="3">
              <p class="fs-12pt pt-2 pb-0 mb-0 fw-400 f-yanone text-left">
                <v-icon class="fs-15pt">mdi-account-multiple</v-icon>
                Lista de áreas (SETORES) do calendário
              </p>
            </v-col>
            <v-col class="py-0 mb-0 text-right" cols="12" md="8" lg="9">
              <v-btn :color="cor.active.S" x-small @click="$refs.formequiperef.show(null)">
                <i class="btn-icon-left" v-i:duo#plus-circle#18></i>
                nova área (setor)
              </v-btn>
            </v-col>
          </v-row>
          <!-- <p><VueObjectView v-model="value" :key="refreshKey"/></p> -->

          <div v-if="loading" class="mt-4">
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
          </div>

          <v-alert v-if="!loading && equipes.length == 0" class="mt-4" outlined color="grey" type="warning" prominent border="left">
            <p class="" style="color: black;">
              Nenhum resultado encontrado.
            </p>
          </v-alert>

          <hr class="mt-4 mb-2">

          <v-card class="p-1 ps-3 my-1" v-for="(equipe,index) in equipes" :key="index">
            <v-container fluid class="m-0 p-0">
              <v-row dense no-gutters align="center" justify="start">
                <v-col cols="auto">
                  <v-icon :color="equipe.cor">group</v-icon>
                </v-col>
                <v-col cols="4">
                  <p class="m-0 ms-2" :style="'color:'+equipe.cor">{{equipe.nome}}</p>
                </v-col>
                <v-col cols="6" >
                  <div class="" v-if="equipe.pessoas != undefined">
                    <avataronline v-for="(user,key,index) in equipe.pessoas" :key="index" :userID="user.id" :size="34"/>
                  </div>
                  <div class="" v-if="equipe.pessoas == undefined">
                    <p class="fs-10pt fw-300 m-0 p-0">nenhum usuário cadastrado</p>
                  </div>
                </v-col>
                <v-col cols="1" class="text-right">
                  <v-btn class="px-0 mx-0" :color="cor.active.D" small icon @click="$refs.promptdialogref.show(equipe)">
                    <i class="btn-icon-left ps-1" v-i:duo#trash#20></i>
                  </v-btn>
                  <v-btn class="px-0 mx-0" :color="cor.active.I" small icon @click="formShow(equipe)">
                    <i class="btn-icon-left ps-1" v-i:duo#pencil#20></i>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-card-text>
      </v-card>
    </div>

    <formequipe ref="formequiperef" @update="formUpdate"/>
    <promptdialog ref="promptdialogref" @confirm="confirmaRemover" />

  </div>

</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import formequipe from "./formEquipe.vue";
import promptdialog from "./promptdialog.vue";
import avataronline from "@/components/avatar-online.vue";

export default {
  name: "equipes",
  components: { formequipe, promptdialog, avataronline },
  data(){
    return {
      loading: false,
      equipes: [],
      equipeParaAtualizar: {},
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    rdb.ref('/calendarios/2022S1/setores').on('value', function(snapshot) {
      self.equipes = Object.assign({}, snapshot.val());
      for(var key in self.equipes) {
        for(var keyUser in self.equipes[key].pessoas) {
          //console.log(keyUser);
          rdb.ref('/usuarios/pessoas/'+keyUser).once('value', function(snapshot) {
            self.equipes[key].pessoas[keyUser] = Object.assign({}, snapshot.val());
          });
        }
      }
      self.loading = false;
      console.log(self.equipes);
    });
  },

  methods:{

    formShow(item) {
      const equipe = clone2(item);
      this.equipeParaAtualizar = item;
      this.$refs.formequiperef.show(equipe);
    },

    formUpdate(dados) {
      console.log("dados",dados);
      var updates = {};
      var elem = clone2(dados.formData);
      if(dados.tipo == "Atualizar") {
        updates["/calendarios/2022S1/setores/"+elem.id] = elem;
        console.log("this.equipeParaAtualizar",this.equipeParaAtualizar);
      } else {
        var newKey = rdb.ref('/calendarios/2022S1/setores/').push().key;
        elem.id = newKey;
        updates["/calendarios/2022S1/setores/"+newKey] = elem;
      }
      console.log(updates);
      rdb.ref().update(updates);
      this.toastS("Dados cadastrados com sucesso!");
      console.log("agora já elvis!");
    },

    confirmaRemover(item) {
      console.log("confirmaRemover",item);
      var elem = clone2(item);
      console.log("confirmaRemover",elem);
      var updates = {};
      updates["/calendarios/2022S1/setores/"+elem.id] = null;
      console.log(updates);
      rdb.ref().update(updates);
      this.toastS("Item removido com sucesso!");
      console.log("agora já elvis!");
    }

  }
}
</script>

<style scoped>

</style>
