<template>

  <v-card class="mx-auto overflow-hidden">
    <topmenu :route="route"/>
    <sidemenu/>
    <v-card-text>
      <calendario :route="route" />
    </v-card-text>
  </v-card>

</template>

<script>
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'
import topmenu from "./top-menu2.vue";
import sidemenu from "./side-menu2.vue";
import { eventBus } from "@/main.js";
import calendario from "@/views/appCalendario/calendario.vue";

export default {
  name: 'appcalendario',
  props: [ "route" ],
  components: { dummy, topmenu, sidemenu, calendario },
  computed: {
  },
  data () {
    return {
      display: true,
      login: false,
      pass: "",
      passValid: "",
      fundo: "appFullScreen",
    }
  },
  created: function() {
    var self = this;
    this.log("Created");
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    //console.log(this.$route.params);
    var routes = [ "professores", "alunos",	"VPA", "diretores", "coordenador" ];
    var password = [ "prof2021", "alunos2021",	"calendariovpa2021", "calendariodiretor2021", "coord2021" ];
    if(this.$route.params.id != undefined) {
      var id = routes.indexOf(this.$route.params.id);
      if(id != -1) {
        //console.log("ok");
        self.login = true;
        self.passValid = password[id];
        self.routes = routes[id];
      }
    }
  },
  methods: {

    clickAcesso() {
      var self = this;
      //console.log(self.pass);
      if(self.pass != self.passValid) {
        this.toastD("Chave inválida!");
        return;
      }
      self.fundo = "";
      self.display = true;
      eventBus.$emit("initCalendar",self.routes);
    }

  }
}
</script>

<style>
.appFullScreen {
  background: url('https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Ffundo-calendario.png?alt=media&token=b8976758-3276-4255-9431-7196deb9598e')
  no-repeat center center fixed !important;
  background-size: cover;
}
</style>
