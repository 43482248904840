<template>
  <v-navigation-drawer v-model="drawer" absolute bottom temporary width="220" :dark="isDark" :color="hexShades(cor.active.B)">
    <div class="row">
      <div class="col-12 mt-1 py-0">
        <div style="width: 90%" class="mx-auto mt-4 mb-0 p-0" v-c:I>
          <div class="text-center">
            <svg v-c:K width="120px" viewBox="0 100 210 70">
              <path d="m 19.506683,163.63042 c -4.991977,-0.96753 -8.829729,-3.60237 -10.0781133,-6.91922 -1.3935114,-3.70245 -0.1636232,-10.37971 2.3176933,-12.58305 2.567311,-2.2797 5.742082,-3.09387 12.996805,-3.33303 l 7.005321,-0.23093 -0.213163,-2.94695 c -0.262822,-3.63348 -1.161264,-4.72355 -4.566459,-5.54044 -2.459546,-0.59005 -8.140563,-0.43879 -11.833544,0.31505 -1.31497,0.26843 -1.648359,0.14641 -1.917571,-0.7018 -0.454097,-1.43074 0.83364,-4.24957 2.56356,-5.61161 1.364262,-1.07413 1.82929,-1.13166 7.768752,-0.96126 5.527408,0.15859 6.672086,0.34646 9.151444,1.50202 3.163692,1.4745 5.124066,3.69521 5.958413,6.74968 0.665938,2.43795 0.722447,19.40279 0.07394,22.19893 -0.803478,3.46434 -3.686332,6.27984 -7.543195,7.36695 -2.116199,0.59648 -9.82689,1.05557 -11.683887,0.69566 z m 9.608486,-7.52102 c 1.755232,-0.90767 2.675274,-3.48611 2.433551,-6.8201 l -0.164896,-2.27434 -3.537872,-0.16051 c -8.233478,-0.37352 -11.371731,0.99988 -11.371731,4.97665 0,1.63021 0.322056,2.40671 1.477354,3.562 1.390472,1.39047 1.718891,1.47736 5.584611,1.47736 2.669447,0 4.622459,-0.26643 5.578983,-0.76106 z" />
              <path d="m 50.700536,161.92167 c -2.19032,-1.7229 -2.492529,-3.78752 -2.294319,-15.67419 0.168814,-10.12378 0.262024,-11.03763 1.380168,-13.53235 2.387298,-5.32635 6.900217,-7.68552 14.701806,-7.68552 5.712975,0 9.093714,1.02616 11.898572,3.61158 3.786376,3.49017 4.021852,4.68429 4.021852,20.39541 v 13.64607 l -2.049045,0.15058 c -2.636954,0.19378 -4.554826,-1.25028 -5.290873,-3.98375 -0.317255,-1.17819 -0.499107,-6.13464 -0.439423,-11.97667 0.11461,-11.21857 -0.195176,-12.55882 -3.189499,-13.79913 -2.294105,-0.95029 -8.334634,-0.83573 -10.323495,0.19577 -0.869737,0.45108 -1.77947,1.22656 -2.02164,1.7233 -0.242169,0.49674 -0.554025,6.93018 -0.693006,14.29653 l -0.252709,13.39337 -2.049035,0.15058 c -1.481375,0.10886 -2.423137,-0.14368 -3.399354,-0.91158 z"/>
              <path d="m 92.576172,161.86392 c -2.676023,-2.10496 -2.817467,-3.09824 -2.817467,-19.78575 0,-11.24958 0.161434,-15.4916 0.606489,-15.93665 1.484096,-1.48409 5.970463,-0.004 7.200816,2.37462 0.688295,1.33103 0.780265,3.79911 0.665334,17.85552 l -0.133377,16.31101 -2.049036,0.15058 c -1.509771,0.11095 -2.423735,-0.14416 -3.472759,-0.96933 z" />
              <path d="m 112.39436,162.85687 c -1.38298,-0.3125 -2.82323,-1.53692 -3.5912,-3.05302 l -0.51866,-1.02396 v -11.1681 c 0,-11.7566 0.026,-12.20594 0.83743,-14.47385 0.63727,-1.78112 1.46626,-3.10954 2.74065,-4.39175 2.19855,-2.21207 4.60213,-3.23972 8.752,-3.74191 2.26196,-0.27374 2.60861,-0.27404 4.72855,-0.004 3.50908,0.44664 5.16763,1.1594 7.08608,3.04523 0.69985,0.68795 1.36302,1.41164 1.47368,1.6082 0.19439,0.34526 0.21701,0.3392 0.66666,-0.17869 1.65245,-1.90319 2.33982,-2.49016 3.73478,-3.18928 1.42622,-0.71478 2.35912,-0.97159 4.66491,-1.28417 2.0009,-0.27125 2.35626,-0.27147 4.73526,-0.003 3.1333,0.35362 3.87507,0.55389 6.23465,1.68331 1.51533,0.72531 3.72697,3.01634 4.51445,4.67651 1.5071,3.17726 1.56149,3.82126 1.56149,18.48718 v 12.39302 l -0.49138,0.39037 c -0.4391,0.34882 -0.64475,0.38105 -1.93219,0.30286 -1.94507,-0.11813 -3.09821,-0.73671 -4.1816,-2.24313 -1.10121,-1.53121 -1.07841,-1.28408 -1.07898,-11.69547 -5.2e-4,-9.67123 -0.12322,-12.13451 -0.66968,-13.44277 -0.98028,-2.34689 -2.61173,-3.13414 -6.51509,-3.14382 -4.17758,-0.0103 -5.95772,0.93672 -6.88688,3.6641 -0.28036,0.82293 -0.31456,2.10839 -0.36033,13.54218 l -0.0505,12.63294 -0.48678,0.38674 c -0.43382,0.34465 -0.64367,0.37721 -1.9285,0.29918 -1.50941,-0.0917 -1.85081,-0.2199 -3.03069,-1.13822 -0.74165,-0.57724 -1.59846,-1.94645 -1.92875,-3.08218 -0.11692,-0.40206 -0.22586,-4.76255 -0.28846,-11.54611 l -0.10059,-10.90005 -0.46687,-0.99423 c -0.57338,-1.22107 -1.49429,-2.00802 -2.90884,-2.48572 -1.39936,-0.47257 -5.41539,-0.51785 -6.89733,-0.0778 -1.93031,0.57324 -2.78723,1.42065 -3.48481,3.44617 -0.22982,0.66733 -0.26928,2.60063 -0.26928,13.19545 0,11.28551 -0.0271,12.45955 -0.29904,12.91978 -0.16446,0.27843 -0.466,0.55107 -0.67009,0.60588 -0.42472,0.11405 -2.16144,0.10265 -2.694,-0.0178 z" />
              <path d="m 180.74308,163.7701 c -2.2562,-0.25513 -4.46714,-0.74518 -5.22424,-1.15792 -0.17066,-0.093 -0.40514,-0.16916 -0.52107,-0.16916 -0.44756,0 -2.80663,-1.63646 -3.65857,-2.53789 -2.29428,-2.4276 -3.2789,-6.77433 -2.40732,-10.62736 0.90193,-3.98717 3.26235,-6.43696 7.34396,-7.62201 2.53471,-0.73592 3.64145,-0.83417 9.39692,-0.83417 h 5.6062 l 0.11406,-0.45446 c 0.21006,-0.83698 -0.0374,-4.7716 -0.34374,-5.46539 -1.17084,-2.65167 -4.19957,-3.47763 -11.13547,-3.03678 -1.80229,0.11456 -3.46593,0.28016 -3.69699,0.36801 -0.39383,0.14974 -1.49007,0.24445 -2.39337,0.20678 -0.25653,-0.0107 -0.50166,-0.21806 -0.69132,-0.58482 -0.25144,-0.48625 -0.26332,-0.70468 -0.0823,-1.51361 0.45425,-2.02979 2.01687,-4.26581 3.45536,-4.94445 0.34418,-0.16237 1.68585,-0.36074 3.34443,-0.49448 2.41878,-0.19504 3.16588,-0.18445 5.89676,0.0836 3.70867,0.36408 5.38652,0.74844 7.32627,1.67831 1.92206,0.92139 3.39061,2.21371 4.49561,3.95611 l 0.5077,0.80054 v 13.00095 c 0,9.75283 -0.0538,13.12581 -0.21553,13.50075 -0.21356,0.49529 -1.05048,1.67205 -1.56977,2.20719 -1.53231,1.57909 -4.1528,2.78255 -7.23853,3.32425 -1.38751,0.24359 -7.04618,0.45875 -8.30906,0.31595 z m 6.79021,-7.11108 c 1.88624,-0.54525 2.87898,-1.32455 3.50965,-2.75508 0.31163,-0.70683 0.57601,-5.65226 0.35025,-6.5517 l -0.11423,-0.45521 h -4.36156 c -2.39886,0 -4.99056,0.0869 -5.75934,0.19321 -3.16611,0.43763 -4.70353,2.04329 -4.70353,4.91229 0,3.00205 1.5621,4.55444 5.00331,4.97222 1.12517,0.1366 5.25268,-0.0779 6.07545,-0.31573 z"/>
              <path d="m 20.330108,115.77879 c -5.034037,-2.94606 -5.539374,-3.51901 -5.539374,-6.28057 0,-1.16839 0.120617,-2.11595 0.268034,-2.10568 0.14742,0.0103 2.42425,1.10965 5.059623,2.44308 l 4.791588,2.42442 4.714415,-2.43741 c 2.592927,-1.34057 4.819073,-2.33275 4.946989,-2.20483 0.127916,0.12792 0.232575,1.0686 0.232575,2.0904 0,1.31335 -0.208354,2.12271 -0.710931,2.76163 -0.761287,0.96783 -8.511422,5.63068 -9.336834,5.6175 -0.272204,-0.004 -2.26394,-1.04319 -4.426085,-2.30854 z"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="col-12 mt0 mb-0 pb-0 pt-1 text-center">
        <p class="f-ralaway fw-300 fs-8pt " v-c:K>
          Calendário Interativo
        </p>
      </div>
    </div>
    <v-divider v-bg:B class="mt-1 mb-0"></v-divider>
    <div dense class="">
      <v-list dense>
        <v-list-item-group v-model="selectedItem" class="pt-0 mt-0">
          <span v-for="(item,index) in menuItens" :key="index">
            <div v-if="index == 0" class="f-roboto fs-10pt fw-700 pt-0 mb-1 mt-0 ms-1" v-c:I>MENU</div>
            <v-list-item class="mb-1" :dark="isDark" @click="goto(item)" style="min-height: 18px;">
              <div class="my-0 py-0">
                <div :color="item.cor" class="my-0 py-0 text-truncate align-middle fs-9pt fw-500 f-raleway" v-if="selectedItem == index">
                  <p class="left my-0 py-0 align-middle" :dark="isDark" style="max-width: 26px;">
                    <span v-if="item.collection == 'duo'">
                      <span v-i:duo##24="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="btn-icon-left" v-if="selectedItem == index"></span>
                      <span v-i:duo##22="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="btn-icon-left" v-if="selectedItem != index"/>
                    </span>
                  </p>
                  <p class="my-0 pb-0 pt-1 left ms-0 f-yanone fw-500 align-middle fs-14pt text-truncate" :style="'max-width: 170px; color: '+item.cor+'; fill: '+item.cor">{{item.title}}</p>
                </div>
                <div :dark="isDark" :color="item.cor" class="text-truncate fs-9pt f-raleway fw-300" v-if="selectedItem != index">
                  <p class="left my-0 py-0 text-truncate" :dark="isDark" style="max-width: 26px;">
                    <span v-if="item.collection == 'duo'">
                      <span v-i:duo##22="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="btn-icon-left" v-if="selectedItem == index"></span>
                      <span v-i:duo##20="item.icon" :style="'color: '+item.cor+'; fill: '+item.cor" class="btn-icon-left" v-if="selectedItem != index"/>
                    </span>
                  </p>
                  <p class="my-0 pb-0 pt-1 left ms-0 text-truncate f-yanone fw-300 fs-11pt text-truncate" :style="'max-width: 180px; color: '+item.cor+'; fill: '+item.cor">{{item.title}}</p>
                </div>
              </div>
            </v-list-item>
          </span>
        </v-list-item-group>
      </v-list>
    </div>

  </v-navigation-drawer>
</template>

<script>
//import { rdb, snapshotValToArray } from '@/firebase.js'
import { eventBus } from "@/main.js";
import { rdb, snapshotValToArray } from '@/firebasedb.js'

export default {
  name: 'SideMenu',
  props: {},
  data(){
    return {
      drawer: null,
      areas: [],
      selectedItem: 0,
      menuItens: [],
    }
  },
  computed: {
    itemhover: function() {
      return {
        '--normal-color': this.cor.active.K,
        '--normal-colorbg': this.cor.active.b,
        '--hover-color': this.hexTints(this.cor.active.P),
        '--active-color': this.cor.active.P,
        '--active-colorbg': this.cor.active.b,
      }
    },
    itemactive: function() {
      return {
        '--normal-color': this.cor.active.K,
        '--normal-colorbg': this.cor.active.b,
        '--active-color': this.cor.active.P,
        '--active-colorbg': this.cor.active.b,
      }
    },
    fundo: function() {
      return {
        '--colorbg1': this.cor.active.b,
        '--colorbg2': this.cor.active.b,
      }
    }
  },
  created() {
    //console.log("Side Menu Created");
  },
  mounted() {
    var self = this;
    //console.log("Side Menu Mounted")

    rdb.ref('/usuarios/setores').on('value', function(snapshot) {
      var setores = snapshot.val();
      //console.log("setores",setores);
      self.menuItens.push({ id: "todos", title: "Todas áreas", collection: "duo", icon: "star", link: "Recursos", cor: "#cbbc00" });
      for(var key in setores) {
        var itemMenu = { id: setores[key].id, title: setores[key].nome, cor: setores[key].cor.hexa, collection: "duo", icon: "calendar", link: "Recursos" };
        self.menuItens.push(itemMenu);
      }
    });

    eventBus.$on("hamburger-click2", function() {
      self.drawer = !self.drawer;
    });
  },
  methods: {

    goto(item) {
      //console.log(item);
      eventBus.$emit("menuClick",item);
      eventBus.$emit('hamburger-click2');
    }

  }
}

</script>

<style scoped lang="scss">

::v-deep .v-list-item {
  padding: 0;
}

.theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {
  opacity: 0;
}

.theme--light.v-list-item:before, .theme--light.v-list-item:hover:before, .theme--light.v-list-item:focus:before {
  opacity: 0;
}

.theme--dark.v-list-item--active:before, .theme--dark.v-list-item--active:hover:before, .theme--dark.v-list-item:focus:before {
  opacity: 0;
}

.theme--dark.v-list-item:before, .theme--dark.v-list-item:hover:before, .theme--dark.v-list-item:focus:before {
  opacity: 0;
}

.v-list-item {
  padding: 0px !important;
  min-height: 40px;
}

.itemactive {
  background: var(--active-colorbg) !important;
  color: var(--active-color) !important;
  fill: var(--active-color) !important;
  width: 100% !important;
}
.itemhover {
  color: var(--normal-color) !important;
  fill: var(--normal-color) !important;
  width: 100% !important;
}
.itemhover:hover .itemhover, .itemhover.hover .itemhover {
  color: var(--hover-color) !important;
  fill: var(--hover-color) !important;
  width: 100% !important;
}

</style>
