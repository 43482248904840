<template>
  <v-overlay :opacity="1" :value="visible" :color="cor.active.B">
    <p class="text-center">
      <img width="180px" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
    </p>
    <p class="text-center">
      <v-progress-circular indeterminate size="64" v-c="cor.P._"></v-progress-circular>
    </p>
    <p class="text-center" v-c="cor.P._">
      carregando...
    </p>
  </v-overlay>
</template>

<script>

export default {
  name: 'appoverlay',
  components: { },
  props: [ 'visible' ],
  computed: {},
  data() {
    return {
    }
  },
  watch: {
  },
  mounted() {

  },
  methods: {

  }
}
</script>
