<template>
  <div class="row m-0 p-0">
    <div class="col-12">
      <v-card outlined class="mt-1">
        <v-card-title class="my-0 py-1">
          <p class="ms-1 my-0 fs-11pt f-raleway fw-700" v-c:P>
            <i v-i:duo#users#18 v-c:P></i>
            Públicos
          </p>
        </v-card-title>
        <hr class="my-0 py-0 mx-2">
        <v-card-text class="">
          
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { notification } from '@/components/notification/notification.js'
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'

export default {
  name: 'listPublicos',
  components: { dummy },
  computed: {
  },
  data () {
    return {
      tab: null,
    }
  },
  methods: {

  }
}
</script>
